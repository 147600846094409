import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';

import Spinner from './components/UI/Spinner/Spinner';
const Main = React.lazy(() => import('./containers/Layout/Main'));

const App = () => {

    let routes = (
        <Switch>
            <Route path='/' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang='en' /></div> }><Main 
            /></Suspense>) } />
            <Redirect to="/" />
        </Switch>
    );

    return (
        <React.Fragment>
        { routes }
        </React.Fragment>
    );
}

export default App;
